const terms = {
  slug: 'f/terms',
  getTerm: slug => `${terms.slug}/${slug}`,
  getTermsByLetter: () => `${terms.slug}`,
  termsQuickSearch: () => `${terms.slug}/search/quick`,
  termsFullSearch: () => `${terms.slug}/search/full`,
  getTermOfTheDay: () => `${terms.slug}/daily`
}

export default terms
