import Vue from 'vue'
import { pathOr, isEmpty, isNil, prop } from 'ramda'
import * as Integrations from '@sentry/integrations'
import {
  getBackendErrorCode,
  getUserDetailsFromAccessToken,
  isString
} from '@/plugins/helper'

import {
  ERROR_MESSAGE,
  getErrorSettingsByBackendError,
  getHttpStatusInfo,
  HTTP_STATUS_CODE
} from 'enums/errorCodes'
import * as types from '@/store/mutation-types'

const ERROR_NAME = {
  DEFAULT_ERROR: 'Error',
  SYNTAX_ERROR: 'SyntaxError',
  TYPE_ERROR: 'TypeError',
  REFERENCE_ERROR: 'ReferenceError'
}

const DEFAULT_ERROR_MESSAGE = 'An error has occurred'
const DEFAULT_STATUS_CODE = HTTP_STATUS_CODE.NOT_FOUND
const UNKNOWN_COMPONENT_NAME = 'unknown'

let storeCopy, Sentry

const SENTRY_SERVER_SIDE_IGNORED_STATUS_CODES = [HTTP_STATUS_CODE.NOT_FOUND]
/**
 * We use different sentry libraries for client and server
 */
if (process.client) {
  Sentry = require('@sentry/browser')
} else {
  Sentry = require('@sentry/node')
}

function initSentry(ctx, env) {
  Sentry.init({
    dsn: ctx.$env.SENTRY_DSN,
    sampleRate: Number(ctx.$env.SENTRY_SAMPLE_RATE),
    allowUrls: [/^https:\/\/(?:.*\.)?financemagnates\.com/],
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'Blocked a restricted frame with origin'
    ],
    integrations: process.client
      ? [
          new Integrations.Vue({
            Vue,
            attachProps: true
          })
        ]
      : [...Sentry.defaultIntegrations],
    environment: env,
    beforeSend(event, hint) {
      if (isErrorToIgnoreBySentry(event)) return null

      return event
    }
  })
}

function isErrorToIgnoreBySentry(event) {
  if (
    process.server &&
    SENTRY_SERVER_SIDE_IGNORED_STATUS_CODES.includes(event?.extra?.statusCode)
  ) {
    return true
  }

  return false
}

export function setSentryUserDetails(details) {
  if (details) {
    const { Email, FirstName, LastName, Type } = details
    Sentry.configureScope(scope => {
      scope.setUser({
        email: Email,
        ...(FirstName ? { firstName: FirstName } : {}),
        ...(LastName ? { lastName: LastName } : {}),
        ...(Type ? { type: Type } : {})
      })
    })
  } else {
    Sentry.configureScope(scope => {
      scope.setUser({})
    })
  }
}

function getCustomTitleForProdError(status) {
  return status === HTTP_STATUS_CODE.NOT_FOUND
    ? 'page not found'
    : 'Internal Client Error'
}

function getCustomError(isDev, name, message = 'page not found') {
  const status =
    name === ERROR_NAME.TYPE_ERROR
      ? HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR
      : HTTP_STATUS_CODE.NOT_FOUND
  const title = isDev ? message : getCustomTitleForProdError(status)
  const errorMessage = prop('message', getHttpStatusInfo(status))

  return { title, status, message: errorMessage }
}

function showError(context, { title, status, message } = {}) {
  const error = context.error || context.$error

  if (error) {
    error({ statusCode: status, title, message })
  }
}

function parseError(errorObj = {}) {
  const isDev = process.env.NODE_ENV !== 'production'
  const { response, message, name } = errorObj

  if (isDev) {
    console.error(errorObj || 'No Error Object')
  }

  if (isEmpty(errorObj) || !response) {
    return showError(this, getCustomError(isDev, name, message))
  }

  const errorCode = getBackendErrorCode(errorObj)

  errorCode
    ? showError(this, getErrorSettingsByBackendError(errorObj))
    : showError(this, { ...getHttpStatusInfo(response.status) })
}

const getSkipProp = prop('skip')

/**
 * ========== Global Error Handling ==========
 **/

function getComponentName(options, vm) {
  if (options.componentName) return options.componentName

  if (!vm) return UNKNOWN_COMPONENT_NAME

  if (vm.$options) {
    return vm.$options.name || vm.$options._componentTag
  }

  return vm.name || UNKNOWN_COMPONENT_NAME
}

export function getStatusCode(options, err) {
  if (options.statusCode) return options.statusCode

  if (!err) return DEFAULT_STATUS_CODE

  return (
    err.statusCode ||
    err.status ||
    (err.response && err.response.status) ||
    DEFAULT_STATUS_CODE
  )
}

/**
 * This is main error handler for this project. Flow:
 * - We use this.$errorHandler(err, this) inside try/catch block in any
 * place inside Vue components. By default such errors will be shown in
 * error message (snackbar) in top-right corner under the header.
 * - We use ctx.app.$errorHandler(err, ctx) inside try/catch block in
 * asyncData or fetch methods inside Vue components. By default such errors
 * will redirect user to Error Page with error details.
 * - All other cases will be handled by global errorHandler (nuxt.config.js,
 * vue-config section). Global handler will call this errorHandler using .call()
 *
 * Note: Errors without try/catch block which were thrown in async functions
 * will not be handled. Please use try/catch.
 *
 * This handler is also responsible for sentry logging.
 * @param err
 * @param vm
 * @param { Object } options                - we can pass different options to
 *                                            overwrite default behavior
 * @param { Boolean } options.showErrorPage - redirect user to Error page or not
 * @param { Boolean } options.showMessage   - show "snackbar" error message or not
 * @param { Number }  options.statusCode    - set custom statusCode
 * @param { String }  options.userMessage   - change default message for user
 * @param { String }  options.message       - change default message for developer
 * @param { String }  options.info          - lifecycle error location from global
 *                                            errorHandler
 * @param { String }  options.componentName - developer can provide component
 *                                            name i.e. for asyncData or fetch methods
 * @returns {boolean}
 */
function handleError(err, vm = {}, options = {}) {
  if (!err) return true

  const isLoggedIn = storeCopy.getters['auth/isLoggedIn']
  const userDetails = storeCopy.getters['auth/userDetails']

  const isDevelopment = process.env.NODE_ENV === 'development'
  const dispatch = storeCopy.dispatch
  const error = err.toString()

  const componentName = getComponentName(options, vm)
  const statusCode = getStatusCode(options, err)

  if (statusCode === HTTP_STATUS_CODE.GONE) {
    storeCopy.commit(`errors/${types.SET_CURRENT_PAGE_AS_410}`)
  }

  if (isDevelopment) {
    console.error(err)
  }

  const data = pathOr(null, ['response', 'data'], err)
  const responseDataString = isString(data) ? data : null
  const errorCode =
    pathOr(null, ['response', 'data', 'ErrorCode'], err) ||
    pathOr(null, ['response', 'data', 'Code'], err)
  const errorMessage =
    pathOr(null, ['response', 'data', 'ErrorMessage'], err) ||
    pathOr(null, ['response', 'data', 'Message'], err)
  const requestId = pathOr(null, ['response', 'data', 'RequestId'], err)
  const message =
    options.message || pathOr(null, ['response', 'data', 'Message'], err)
  const messageDetail = pathOr(null, ['response', 'data', 'MessageDetail'], err)
  const modelState = pathOr(null, ['response', 'data', 'ModelState'], err)
  const requestHeaders = pathOr(null, ['response', 'headers'], err)
  const requestUrl = pathOr(null, ['response', 'config', 'url'], err)
  const requestParams = pathOr(null, ['response', 'config', 'params'], err)
  const requestPayload = pathOr(null, ['response', 'config', 'data'], err)

  const errorSettings = getErrorSettingsByBackendError(err) || {}
  const skip = getSkipProp(options) || getSkipProp(errorSettings)
  if (skip) return true

  const userMessage =
    options.userMessage ||
    (errorCode ? pathOr('', ['message'], errorSettings) : DEFAULT_ERROR_MESSAGE)
  const stack = pathOr('', ['stack'], err).split('\n ')

  const payload = {
    error,
    statusCode,
    message,
    messageDetail,
    userMessage,
    errorCode,
    errorMessage,
    requestId,
    info: options.info,
    componentName,
    stack,
    modelState,
    responseDataString,
    requestHeaders,
    requestUrl,
    requestParams,
    requestPayload
  }

  if (process.server) {
    console.error(JSON.stringify(payload, null, 4))
  }

  /**
   * We don't send errors to Sentry on Development environment
   */
  if (!isDevelopment) {
    try {
      /**
       * Sentry
       * If user has token but userDetails are missing we parse token and get
       * user email
       */
      if (isLoggedIn && !userDetails.Id) {
        const details = getUserDetailsFromAccessToken(
          storeCopy.getters['auth/accessToken']
        )

        if (details) {
          const { Email } = details
          setSentryUserDetails({ Email })
        }
      }

      /**
       * Sentry
       * Adding additional information to Sentry
       */
      Sentry.configureScope(scope => {
        scope.setTag('Side', process.client ? 'Client' : 'Server')
        Object.entries(payload)
          .filter(([key, value]) => value)
          .forEach(([key, value]) => {
            scope.setExtra(key, value)
          })
      })

      Sentry.captureException(err)
    } catch (err) {
      console.log('Sentry issue:', err)
    }
  }

  /**
   * First we check options and if options has prop "showErrorPage" we
   * redirect user to Error Page
   * If "showErrorPage" option is missing we check error settings from
   * enums/errorCodes.js. If these settings include showErrorPage option
   * we also redirect user to Error Page
   * If there are no options and settings with "showErrorPage" options we also
   * redirect user to Error Page in case when context is not Vue
   * instance. It means that error is handled inside asyncData or fetch.
   * @type { boolean }
   */
  const showErrorPage =
    options.showErrorPage ||
    (isNil(options.showErrorPage) &&
      (prop('isPageError', errorSettings) || !payload.componentName))

  /**
   * In this const we read "showMessage" prop from errorSettings object which
   * are located in enums/errorCodes.js
   */
  const showMessageInSettings =
    isNil(prop('showMessage', options)) && prop('showMessage', errorSettings)

  /**
   * We show message to user in case:
   * - on production we show messages only when we don't show Error Page
   * - we have "showMessage" prop in options
   * - we have "showMessage" prop in error settings from enums/errorCodes.js
   * @type { boolean }
   */
  const showMessageToUser = Boolean(
    !showErrorPage &&
      componentName !== UNKNOWN_COMPONENT_NAME &&
      (prop('showMessage', options) || showMessageInSettings)
  )
  /**
   * We always show message in Development mode
   * @type { boolean }
   */
  const showMessage = isDevelopment || showMessageToUser

  if (showMessage) {
    if (isDevelopment) {
      dispatch('errors/addErrorMessageToQueue', {
        ...payload,
        showMessageToUser
      })
    } else if (showMessageToUser) {
      console.error(payload.userMessage)
    }
  }

  if (showErrorPage) {
    parseError.call(vm, err)
  }

  /**
   * We do not propagate error to globalHandler because globalHandler uses
   * this function to process any errors
   */
  return true
}

const errorHandler = {
  install(Vue) {
    Vue.prototype.$errorHandler = handleError
  }
}
Vue.use(errorHandler)

export default (ctx, inject) => {
  inject('error', parseError.bind(ctx))
  inject('errorHandler', handleError)
  storeCopy = ctx.store
  initSentry(ctx, storeCopy.$env.ENVIRONMENT)
}

Vue.config.errorHandler = handleError

export function throwPageNotFoundError(ctx, componentName) {
  const err = new Error('Page not found')
  handleError(err, ctx, {
    showErrorPage: true,
    showMessage: false,
    userMessage: ERROR_MESSAGE.PAGE_NOT_FOUND,
    statusCode: 404,
    ...(componentName ? { componentName } : {})
  })
}
