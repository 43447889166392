<template>
  <div class="global-wrapper" :class="globalWrapDynamicClass">
    <a-banner
      :class="topBannerClass"
      :banner-settings="bannerSettings.TOP_SIDE"
      :custom-breakpoint-handler="wideSkyBreakpointHandler"
      class="top-side-unit"
    />
    <a-sticky
      :id="$options.consts.TOP_STICKY_CONTAINER_ID"
      :data-ref="$options.consts.REFS.LAYOUT_TOP_STICKY_ELEMENT"
      class="top-sticky-container"
    >
      <a-visibility hide :on="[$breakpoint.mobile]">
        <top-header-strip />
      </a-visibility>
      <a-header
        :last-modified="headerMenuItemsLastModified"
        :is-desktop-header-mounted.sync="isDesktopHeaderMounted"
        :is-mobile-header-mounted.sync="isMobileHeaderMounted"
      />
    </a-sticky>
    <div class="page-wrap" :data-ref="$options.consts.REFS.LAYOUT_PAGE_WRAP">
      <div
        class="content-wrapper"
        :data-ref="$options.consts.REFS.CONTENT_WRAPPER"
      >
        <nuxt ref="nuxt" />
      </div>
      <div class="widgets-wrapper">
        <a-visibility show :from="$breakpoint.desktopMd">
          <a-layout-widgets :style="contentTopPadding" />
        </a-visibility>
      </div>
      <a-layout-banners-section
        :layout-top-padding="layoutTopPadding"
        :first-banner-container-height="
          $_sideBannerMovement_containerHeights[0]
        "
        :second-banner-container-height="
          $_sideBannerMovement_containerHeights[1]
        "
      />
    </div>

    <!--lazy-->
    <!--    <a-cookies-pop-up />-->

    <a-footer :last-modified="footerLastModified" />

    <!--lazy-->
    <a-auth-form />

    <!--lazy-->
    <a-pedia-assistant />

    <!--lazy-->
    <a-make-your-account-better />

    <client-only>
      <a-error-handler-notifier v-if="isDevelopment" />
    </client-only>

    <a-cross-domain-cookies />

    <a-device-id-cookie-iframe />

    <a-google-analytics-cookies-iframe />

    <a-news-letter-modal />

    <a-char-width />

    <gtm-no-script />

    <!-- Use this component to define styles with "!important" modifier
         Previously was used for "iubenda". -->
    <!--    <a-non-amp-important-styles v-if="$helper.isNotAmpPage($route.name)" />-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { pathOr } from 'ramda'

import mixins from '@/utils/mixins'
import { ROUTE_NAME } from 'enums/routes'
import ABanner from 'shared/ABanner'
import {
  BANNER_SETTINGS,
  WIDE_SKY_BANNERS_BREAKPOINT_NAME,
  WIDE_SKY_BANNERS_BREAKPOINT_VALUE
} from 'enums/banners/banner-settings'
import { BANNER_SETTINGS_BY_ROUTE_NAME } from 'enums/banners/banner-settings-by-route-name'
import AHeader from '@/components/AHeader'
import AAuthForm from 'shared/AAuthForm/lazy'
import ALayoutWidgets from '@/components/_layout/ALayoutWidgets'
import APediaAssistant from '@/components/_layout/APediaAssistant/lazy'
import AMakeYourAccountBetter from 'shared/AMakeYourAccountBetter/lazy'
import ACrossDomainCookies from '@/components/_layout/ACrossDomainCookies'
import ADeviceIdCookieIframe from '@/components/_layout/ADeviceIdCookieIframe'
import AGoogleAnalyticsCookiesIframe from '@/components/_layout/AGoogleAnalyticsCookiesIframe'
import ANewsLetterModal from '@/components/_layout/ANewsLetterModal'
import { LAYOUT_STICKY_TOP_PADDING } from 'enums/settings'
import ACharWidth from 'shared/ACharWidth'
import GtmNoScript from 'shared/GtmNoScript'
import { QUERY_PARAM } from 'enums/query-param'
import ALayoutBannersSection from '@/components/_layout/ALayoutBannersSection/index.vue'
import { TOP_STICKY_CONTAINER_ID } from 'enums/header'
import ASticky from 'shared/ASticky'
import { REFS } from 'enums/external-refs'
import {
  hydrateWhenIdle,
  hydrateWhenVisible
} from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'

const STICKY_TOP_PADDING_BY_ROUTE_NAME = {}

const hydrationOptions = { observerOptions: { rootMargin: '100px' } }

function getStickyTopPaddingByRouteName(routeName) {
  if (STICKY_TOP_PADDING_BY_ROUTE_NAME[routeName] != null) {
    return STICKY_TOP_PADDING_BY_ROUTE_NAME[routeName]
  }

  return LAYOUT_STICKY_TOP_PADDING
}

export default {
  name: 'DefaultLayout',
  components: {
    ALayoutBannersSection,
    GtmNoScript,
    ABanner,
    TopHeaderStrip: hydrateWhenIdle(() =>
      import('@/components/AHeader/TopHeaderStrip')
    ),
    AHeader,
    AFooter: hydrateWhenVisible(() => import('@/components/AFooter'), {
      ...hydrationOptions,
      props: ['last-modified']
    }),
    AAuthForm,
    ALayoutWidgets,
    APediaAssistant,
    AMakeYourAccountBetter,
    AErrorHandlerNotifier: hydrateWhenVisible(() =>
      import('shared/AErrorHandlerNotifier')
    ),
    ACrossDomainCookies,
    ANewsLetterModal,
    ACharWidth,
    AGoogleAnalyticsCookiesIframe,
    ADeviceIdCookieIframe,
    ASticky
    // ANonAmpImportantStyles: () =>
    //   import('@/components/_layout/ANonAmpImportantStyles')
  },
  mixins: [
    mixins.windowWidth,
    mixins.googleBanners,
    mixins.dataRefsInDom,
    mixins.sideBannersMovement,
    mixins.userDetails,
    mixins.oneSignal,
    mixins.countryInfo,
    mixins.signalr,
    mixins.documentVisibility,
    mixins.actionsOnQueryParams,
    mixins.internetConnectionState,
    mixins.oneTrustListener
  ],
  consts: {
    TOP_STICKY_CONTAINER_ID,
    REFS
  },
  head() {
    const isGtmDisabled = !!this.$route.query[QUERY_PARAM.DISABLE_GTM]
    const isCookiePopupHidden = !!this.$route.query[
      QUERY_PARAM.HIDE_COOKIE_POPUP
    ]

    const oneTrustScript = {
      src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      type: 'text/javascript',
      'data-domain-script': this.$env.ONE_TRUST_SCRIPT_ID,
      defer: true
    }

    const signalRScript = {
      src: '/signalr.min.js',
      type: 'text/javascript',
      defer: true
    }

    const gtmScript = {
      /** Google Tag Manager **/
      hid: 'gtm',
      innerHTML:
        '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(\n' +
        "    {'gtm.start': new Date().getTime(),event:'gtm.js'}\n" +
        '  );var f=d.getElementsByTagName(s)[0],\n' +
        "    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
        "    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
        `  })(window,document,'script','dataLayer','${this.$env.GTM_CONTAINER_ID}');`
    }

    const PRODUCTION_SCRIPTS = [...(isGtmDisabled ? [] : [gtmScript])]

    const hideCookiePopupStyle = {
      type: 'text/css',
      innerHTML: '#onetrust-consent-sdk { display: none; }'
    }

    return {
      style: [...(isCookiePopupHidden ? [hideCookiePopupStyle] : [])],
      script: [
        ...(this.isPreviewMode ? [] : [oneTrustScript]),
        signalRScript,
        ...(process.env.NODE_ENV === 'production' ? PRODUCTION_SCRIPTS : [])
      ],
      __dangerouslyDisableSanitizersByTagID: {
        gtm: ['innerHTML']
      }
    }
  },
  data() {
    return {
      layoutTopPadding: getStickyTopPaddingByRouteName(this.$route.name),
      isDesktopHeaderMounted: false,
      isMobileHeaderMounted: false
    }
  },
  computed: {
    ...mapGetters({
      isPageLoaded: 'isPageLoaded',
      isCurrentPageArticle: 'articles/isCurrentPageArticle',
      isPreviewMode: 'isPreviewMode',
      headerMenuItemsLastModified: 'headerMenuItemsLastModified',
      footerMenuItemsLastModified: 'footerMenuItemsLastModified',
      stayConnectedLastModified: 'stayConnectedLastModified'
    }),
    isDevelopment() {
      return process.env.NODE_ENV === 'development'
    },
    globalWrapDynamicClass() {
      return {
        'preview-mode': this.isPreviewMode
      }
    },
    contentTopPadding() {
      return {
        paddingTop: `${this.layoutTopPadding}px`
      }
    },
    bannerSettings() {
      if (this.$route.name === ROUTE_NAME.CATEGORY_SUBCATEGORY) {
        return this.isCurrentPageArticle
          ? BANNER_SETTINGS.ARTICLE
          : BANNER_SETTINGS.ALL_NEWS
      } else {
        return BANNER_SETTINGS_BY_ROUTE_NAME[this.$route.name] || {}
      }
    },
    topBannerClass() {
      /**
       * Personal area pages have an additional fixed tabs section under a header that would block a part of a top
       * banner
       */
      const profilePathRegex = /^\/profile\/.*/
      return pathOr(null, ['TOP_BANNER', 'mobile'], this.bannerSettings) &&
        profilePathRegex.test(this.$route.path)
        ? 'profile-top-unit'
        : ''
    },
    footerLastModified() {
      return this.footerMenuItemsLastModified + this.stayConnectedLastModified
    },
    routeNameToWatch() {
      return (this.isPageLoaded && this.$route.name) || null
    }
  },
  watch: {
    routeNameToWatch: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.layoutTopPadding = getStickyTopPaddingByRouteName(
              this.$route.name
            )
          })
        }
      }
    }
  },
  methods: {
    ...mapActions({
      requestAuthStatus: 'auth/requestAuthStatus'
    }),
    wideSkyBreakpointHandler(width) {
      if (width >= WIDE_SKY_BANNERS_BREAKPOINT_VALUE) {
        return WIDE_SKY_BANNERS_BREAKPOINT_NAME
      }

      return null
    }
  },
  mounted() {
    this.requestAuthStatus()
  }
}
</script>

<style lang="scss" scoped>
.profile-top-unit {
  @include mobile {
    margin-top: 70px;
    margin-bottom: 20px;
  }
}

.top-unit {
  @include mobile {
    margin-bottom: 5px;
  }
}

.top-side-unit {
  margin-bottom: 20px;

  &.empty {
    margin-bottom: 0;
  }
}

.global-wrapper__scroll-to-top {
  bottom: 0;

  @include tablet {
    right: 15px;
  }
}

.page-wrap {
  /deep/ .widgets-wrapper {
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    padding-left: 5px;
  }

  .widgets-wrapper {
    @include desktop-sm {
      display: none;
      order: 1;
    }
  }
}
</style>
