<template>
  <a
    v-if="link"
    :href="link"
    v-bind="relAttr"
    :target="targetWindow"
    :title="title"
    :aria-label="ariaLabel"
    v-on="$listeners"
    v-click="onClick"
  >
    <slot />
  </a>
  <span v-else v-on="$listeners">
    <slot />
  </span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { equals, pick } from 'ramda'

import { PROP_TYPES, propValidator } from '@/utils/validators'
import { addEndingSlash, wrapIntoSlashes } from '@fmpedia/helpers'
import mixins from '@/utils/mixins'
import { TARGET } from 'enums/links'
import { decodeHTML } from 'entities'
import {
  BASE_HTML_REL_ATTRIBUTE_VALUES,
  HTML_REL_ATTRIBUTE_VALUE
} from '@fmpedia/enums'
import { LinkRelAttributeConverter } from '@fmpedia/html-tools'
import { HEADER_SCROLL_OFFSET } from 'enums/header-scroll-offset'

const SCROLL_TO_DELAY = 500
const PREVIEW_HEADER_OFFSET = 80

export default {
  name: 'ALink',
  mixins: [mixins.urlGenerators],
  props: {
    directive: propValidator([PROP_TYPES.STRING], false, null),
    removeDirective: propValidator([PROP_TYPES.BOOLEAN], false, false),
    openInNewTab: propValidator(
      [PROP_TYPES.BOOLEAN, PROP_TYPES.STRING],
      false,
      null
    ),
    to: propValidator([PROP_TYPES.STRING, PROP_TYPES.OBJECT], false),
    // /**
    //  * This prop is used for router-link to use "name" property instead of "path"
    //  * In case we received wrong path from backend usually we will be redirected to 404 error page,
    //  * but if first part of path is the same as existing parent route we will be redirected to
    //  * dynamic route _category.vue as dynamic path can be any path. But in such case code on
    //  * _category page will not be executed and we will be redirected to all-news empty page.
    //  */
    // routeByName: {
    //   type: Boolean,
    //   default: false
    // },
    title: {
      type: String
    },
    preventDefault: {
      type: Boolean,
      required: false
    },
    ariaLabel: propValidator([PROP_TYPES.STRING], false)
  },
  data() {
    return {
      reg: new RegExp(`${this.$env.DOMAIN_URL}/?`, 'i')
    }
  },
  computed: {
    ...mapGetters({
      isPreviewMode: 'isPreviewMode'
    }),
    link() {
      if (this.isInnerLink) {
        if (
          this.$helper.isObject(this.to) &&
          this.$helper.isAmpPage(this.$route.name)
        ) {
          return this.generateAmpLinkFromObject()
        }
        return this.generateRouterPathByUrl(this.to)
      } else if (this.checkIfFmDirOrFlLink(this.to)) {
        const { address, query, hash } = this.$helper.parseUrl(this.to)

        return this.$helper.generateUrlFromObject({
          address,
          addressModifierFn: addEndingSlash,
          query,
          hash
        })
      } else {
        /**
         * We decode HTML entities here. More details in this bug:
         * https://adraba.atlassian.net/browse/FMP-15952
         */
        return decodeHTML(this.to)
      }
    },
    isInnerLink() {
      return this.to ? this.checkIfInnerLink(this.to) : true
    },
    openInNewTabBoolean() {
      try {
        return JSON.parse(this.openInNewTab)
      } catch {
        return null
      }
    },
    targetWindow() {
      if (this.openInNewTabBoolean != null) {
        return this.openInNewTabBoolean ? TARGET.BLANK : TARGET.SELF
      }

      /**
       * The default value for external links should be "open in new tab"
       */
      return this.isInnerLink || this.checkIfFmDirOrFlLink(this.to)
        ? TARGET.SELF
        : TARGET.BLANK
    },
    relAttr() {
      return this.removeDirective || !this.relProps
        ? {}
        : {
            rel: this.relProps
          }
    },
    relProps() {
      const {
        htmlRelString,
        htmlRelStringWithBaseDirectives
      } = new LinkRelAttributeConverter(this.directive)

      if (this.directive != null) {
        return this.isInnerLink
          ? htmlRelString
          : htmlRelStringWithBaseDirectives
      }

      /**
       * The default value for external links should be "nofollow"
       */
      return this.isInnerLink || this.checkIfFmDirOrFlLink(this.to)
        ? ''
        : LinkRelAttributeConverter.generateRelString([
            HTML_REL_ATTRIBUTE_VALUE.NOFOLLOW,
            ...BASE_HTML_REL_ATTRIBUTE_VALUES
          ])
    }
  },
  methods: {
    ...mapActions({
      concurrentRouterPush: 'router/concurrentRouterPush'
    }),
    async onClick(event) {
      const newRoute = this.resolveRoute(this.to)
      const { params, query, name, hash: toHash, path, fullPath } = newRoute
      const handledHash = this.$helper.removeEndingSlashes(toHash)

      /**
       * We only compare route path here, but not params or query. In preview,
       * the route has a previewId query param, but the link with a hash inside
       * article body doesn't have it.
       */
      const isLinkWithHashAndSameRoutePath =
        handledHash && this.checkIfTheSameRoutePath(path)

      /**
       * In preview mode we don't allow editors to navigate between pages
       */
      if (this.isPreviewMode) {
        event.preventDefault()

        if (isLinkWithHashAndSameRoutePath) {
          /**
           * The default link behavior here will lead to infinite progress bar
           * running in the preview mode. That's why, we scroll using $scrollTo.
           */
          this.$scrollTo(handledHash, SCROLL_TO_DELAY, {
            offset: HEADER_SCROLL_OFFSET - PREVIEW_HEADER_OFFSET
          })
        }

        return
      }

      this.$emit('click', event)

      if (this.preventDefault) {
        event.preventDefault()
        return
      }

      if (!this.isInnerLink || this.openInNewTabBoolean) return

      const fromHash = this.$route.hash

      const isTheSameRoute =
        this.checkIfTheSameRoute(name, query, params) ||
        this.checkIfFromPaginatedRouteToTheSame(newRoute)

      if (process.client && isTheSameRoute && !toHash && !fromHash) {
        window.location.reload()
      }

      if (this.$helper.isUrlWithPagination() && name === this.$route.name) {
        window.location.href = this.link
      } else if (!isLinkWithHashAndSameRoutePath) {
        await this.followRouterLink({
          path: this.handleMultipleSlashes(path),
          params,
          query,
          handledHash
        })
      }

      if (isTheSameRoute && !handledHash) {
        this.$scrollTo('body', SCROLL_TO_DELAY)
      }

      if (handledHash) {
        this.$nextTick(() => {
          /**
           * router.push or router.replace would scroll the page up and show
           * a progress bar, which is not expected in such case
           */
          window.history.replaceState(null, '', fullPath)

          this.$scrollTo(handledHash, SCROLL_TO_DELAY, {
            offset: HEADER_SCROLL_OFFSET
          })
        })
      }

      event.preventDefault()
    },
    async followRouterLink({ path, params, query, handledHash }) {
      const args = {
        router: this.$router,
        to: {
          path,
          params,
          query,
          hash: handledHash
        }
      }
      await this.concurrentRouterPush({ args })
    },
    checkIfTheSameRoutePath(path) {
      return this.$route.path === path
    },
    checkIfTheSameRoute(name, query, params) {
      const currentRoute = pick(['name', 'query', 'params'], this.$route)
      return equals({ name, query, params }, currentRoute)
    },
    checkIfFromPaginatedRouteToTheSame(newRoute) {
      const {
        isParentRouteFullPathChanged,
        isFromPaginatedToParent
      } = this.$helper.compareRoutes(this.$route, newRoute)

      return !isParentRouteFullPathChanged && isFromPaginatedToParent
    },
    resolveRoute(url) {
      if (!url) return { fullPath: '/' }

      if (typeof url === 'string') {
        const { address, query, hash } = this.$helper.parseUrl(url)

        const addressModifierFn = url => {
          const innerLink = url.replace(this.reg, '') || '/'

          return wrapIntoSlashes(innerLink)
        }
        const link = this.$helper.generateUrlFromObject({
          address,
          addressModifierFn,
          query,
          hash
        })

        return link ? this.$router.resolve(link).resolved : null
      }

      return this.$router.resolve(url).resolved
    },
    checkIfInnerLink(url) {
      return (
        this.reg.test(url) ||
        (!this.checkIfHasProtocol(url) && !this.checkIfEmail(url))
      )
    },
    checkIfFmDirOrFlLink(url) {
      if (typeof url !== 'string') return false

      return (
        this.$helper.isStringStartsWith(this.$env.FL_DOMAIN_URL, url) ||
        this.$helper.isStringStartsWith(this.$env.FM_DIR_DOMAIN_URL, url)
      )
    },
    checkIfHasProtocol(url) {
      return /https?:\/\//.test(url)
    },
    checkIfEmail(url) {
      return /mailto:/.test(url)
    },
    generateRouterPathByUrl(link) {
      const resolvedLink = this.resolveRoute(link)
      if (!resolvedLink) return null

      const url = resolvedLink.fullPath.replace(/\/+/g, '/')
      const { address, query, hash } = this.$helper.parseUrl(url)

      return this.$helper.generateUrlFromObject({
        address,
        addressModifierFn: wrapIntoSlashes,
        query,
        hash
      })
    },
    generateAmpLinkFromObject() {
      const resolve = this.resolveRoute(this.to)
      const query = this.$helper.serializeQueryParams(resolve.query)
      const hash = this.to.hash

      const queryString = query ? `?${query}` : ''
      const hashString = hash ? `${hash}` : ''

      const link = this.$helper.generateUrlFromObject({
        address: resolve.path,
        query: queryString,
        hash: hashString
      })
      return this.generateFmUrl(link)
    },
    handleMultipleSlashes(path) {
      const regexpString = '\\/+'
      const regexp = new RegExp(regexpString, 'g')

      return path.replace(regexp, '/')
    }
  }
}
</script>
