import { GAP_BETWEEN_SECTIONS, GAP_BETWEEN_SIDE_BANNERS } from './enums'

const FIRST_SIDE_BANNER_CONTAINER_HEIGHT = 1200

export function getFirstArticleBannerContainerHeight({ content }) {
  if (!content) return

  const { height: contentHeight } = content

  return Math.min(Math.floor(contentHeight), FIRST_SIDE_BANNER_CONTAINER_HEIGHT)
}

const MIN_HEIGHT_TO_SHOW_SECOND_BANNER = 600 // banner height

export function getSecondArticleBannerContainerHeight({
  content,
  moreFromTheAuthor,
  categoryNews
}) {
  if (!content) return

  const { height: contentHeight } = content
  const { height: moreFromTheAuthorHeight } = moreFromTheAuthor || { height: 0 }
  const { height: categoryNewsHeight } = categoryNews || { height: 0 }

  const resultHeight =
    contentHeight +
    (moreFromTheAuthor ? GAP_BETWEEN_SECTIONS : 0) +
    moreFromTheAuthorHeight +
    (categoryNews ? GAP_BETWEEN_SECTIONS : 0) +
    categoryNewsHeight -
    FIRST_SIDE_BANNER_CONTAINER_HEIGHT -
    GAP_BETWEEN_SIDE_BANNERS

  return resultHeight >= MIN_HEIGHT_TO_SHOW_SECOND_BANNER
    ? Math.floor(resultHeight)
    : null
}
