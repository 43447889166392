/**
 * Api available routes
 */

const users = {
  slug: 'users',
  loginWithEmail: () => `${users.slug}/login`,
  refreshAccessToken: () => `${users.slug}/get-access-token`,
  getCurrentUserDetails: () => `${users.slug}/get-current-user-details`,
  becomeAContributor: () => `${users.slug}/become-a-contributor`,
  registrationWithEmail: () => `${users.slug}/register`,
  forgotPassword: () => `${users.slug}/forgot-password`,
  resetPassword: () => `${users.slug}/reset-password`,
  changePassword: () => `${users.slug}/change-password`,
  confirmationOfRegistration: () => `${users.slug}/confirm-user-registration`,
  registrationWithFacebook: () => `${users.slug}/facebook/register`,
  loginWithFacebook: () => `${users.slug}/facebook/login`,
  registrationWithGoogle: () => `${users.slug}/google/register`,
  loginWithGoogle: () => `${users.slug}/google/login`,
  twitterAuthUrl: () => `${users.slug}/twitter/request-auth-url`,
  twitterRequestAccessToken: () => `${users.slug}/twitter/request-access-token`,
  twitterGetUserDetails: () => `${users.slug}/twitter/get-user-details`,
  twitterLogin: () => `${users.slug}/twitter/login`,
  twitterRegister: () => `${users.slug}/twitter/register`,
  getProfileInfo: () => `${users.slug}/get-profile-info`,
  getProfileSettings: () => `${users.slug}/get-profile-settings`,
  updateProfileInfo: () => `${users.slug}/update-profile-info`,
  getProfileBasicInfo: () => `${users.slug}/get-profile-basic-info`,
  getProfileSummary: () => `${users.slug}/get-profile-summary`,
  uploadProfilePhoto: () => `${users.slug}/upload-profile-photo`,
  followTags: () => `${users.slug}/follow-tags`,
  followCompanies: () => `${users.slug}/follow-companies`,
  followAuthors: () => `${users.slug}/follow-authors`,
  followCategories: () => `${users.slug}/follow-categories`,
  unfollowTags: () => `${users.slug}/unfollow-tags`,
  unfollowCompanies: () => `${users.slug}/unfollow-companies`,
  unfollowAuthors: () => `${users.slug}/unfollow-authors`,
  unfollowCategories: () => `${users.slug}/unfollow-categories`,
  updateAnnouncementSubscription: () =>
    `${users.slug}/update-announcement-subscription`,
  getNotifications: () => `${users.slug}/get-notifications`,
  deleteNotification: () => `${users.slug}/delete-notification`,
  clearAllNotifications: () => `${users.slug}/clear-all-notifications`,
  getAnnouncements: () => `${users.slug}/get-announcements`,
  deleteAnnouncement: () => `${users.slug}/delete-announcement`,
  clearAllAnnouncements: () => `${users.slug}/clear-all-announcements`,
  getAuthor: () => `${users.slug}/get-author`,
  getAuthorArticles: () => `${users.slug}/get-author-articles`,
  getSavedArticles: () => `${users.slug}/get-saved-articles`,
  deleteSavedArticle: () => `${users.slug}/delete-saved-article`,
  deactivateUserProfile: () => `${users.slug}/deactivate-user-profile`,
  getAllPosts: () => `${users.slug}/get-all-posts`,
  getOnboardingDetails: () => `${users.slug}/get-onboarding-details`,
  completeUserOnboardingFlow: () =>
    `${users.slug}/complete-user-onboarding-flow`,
  registerUserSession: () => `${users.slug}/register-user-session`,
  getCompanies: () => `${users.slug}/get-companies`,
  getCompanyBySlug: () => `${users.slug}/get-company-by-slug`,
  getCompanyVideos: () => `${users.slug}/get-company-videos`,
  getCompanyVideoBySlug: () => `${users.slug}/get-company-video-by-slug`,
  getCompanyNews: () => `${users.slug}/get-company-news`,
  getCompanyArticleBySlug: () => `${users.slug}/get-company-article-by-slug`,
  renewCompany: () => `${users.slug}/renew-company`,
  renewCompanyFeatures: () => `${users.slug}/renew-company-features`,
  getPostBySlug: () => `${users.slug}/get-post-by-slug`,
  savePost: () => `${users.slug}/save-post`,
  updatePost: () => `${users.slug}/update-post`,
  deletePost: () => `${users.slug}/delete-post`,
  submitPost: () => `${users.slug}/submit-post`,
  confirmBecomeAContributorFlow: () =>
    `${users.slug}/confirm-become-a-contributor-flow`,
  confirmBecomeASponsoredContributorFlow: () =>
    `${users.slug}/confirm-become-a-sponsored-contributor-flow`,
  requestUserProfileReactivation: () =>
    `${users.slug}/request-user-profile-reactivation`,
  reactivateUserProfile: () => `${users.slug}/reactivate-user-profile`,
  reactivateFacebookUserProfile: () =>
    `${users.slug}/facebook/reactivate-user-profile`,
  reactivateGoogleUserProfile: () =>
    `${users.slug}/google/reactivate-user-profile`,
  reactivateTwitterUserProfile: () =>
    `${users.slug}/twitter/reactivate-user-profile`,
  getSearchHistory: () => `${users.slug}/get-search-history`,
  deleteSearchHistoryRecords: () =>
    `${users.slug}/delete-search-history-records`,
  clearSearchHistory: () => `${users.slug}/clear-search-history`,
  getSavedEntities: () => `${users.slug}/get-saved-entities`,
  getCompanyState: () => `${users.slug}/get-company-state`,
  clearAllSavedArticles: () => `${users.slug}/clear-all-saved-articles`
}

const layout = {
  slug: 'layout',
  getBecomeAContributerExpertises: () =>
    `${layout.slug}/get-become-a-contributor-expertises` // used in flLeads
}

const articles = {
  slug: 'articles',
  getHomepageArticles: () => `${articles.slug}/get-homepage-articles`,
  getMostViewedArticles: () => `${articles.slug}/get-most-viewed-articles`,
  getAllNews: () => `${articles.slug}/get-all-news`,
  getArticleByTagSlug: () => `${articles.slug}/get-articles-by-tag-slug`,
  getArticleContentBySlug: () => `${articles.slug}/get-article-content-by-slug`,
  getPreviewArticleContent: () =>
    `${articles.slug}/get-preview-article-content`,
  deletePreviewArticleContent: () =>
    `${articles.slug}/delete-preview-article-content`,
  getArticleByTermSlug: () => `${articles.slug}/get-articles-by-term-slug`,
  getArticleByCategory: () => `${articles.slug}/get-articles-by-category`,
  getArticlesBySubcategory: () =>
    `${articles.slug}/get-articles-by-subcategory`,
  checkSlug: () => `${articles.slug}/check-slug`,
  getEducationArticles: () => `${articles.slug}/get-education-articles`,
  getAllNewsPinnedCategories: () =>
    `${articles.slug}/get-all-news-pinned-categories`,
  getCategoryPagePinnedSubcategories: () =>
    `${articles.slug}/get-category-page-pinned-subcategories`,
  getArticleEbook: () => `${articles.slug}/get-article-ebook-by-slug`,
  getMigratedArticleSlugs: () => `${articles.slug}/get-migrated-article-slugs`
}

const categories = {
  slug: 'backoffice/categories',
  getCategoriesList: () => `${categories.slug}`
}

const globalSearch = {
  slug: 'globalsearch',
  quickSearch: () => `${globalSearch.slug}/quick-search`,
  getAllResults: () => `${globalSearch.slug}/view-all-results`
}

const leads = {
  slug: 'leads',
  submitContactUsLead: () => `${leads.slug}/submit-contact-us-lead`,
  newsletterSubscription: () => `${leads.slug}/newsletter-subscription`,
  eventsNewsletterSubscription: () =>
    `${leads.slug}/events-newsletter-subscription`,
  becomeAContributor: () => `${leads.slug}/become-a-contributor`, // used in flLeads
  becomeAContributorFileUpload: () =>
    `${leads.slug}/become-a-contributor-file-upload`,
  submitEbookLead: () => `${leads.slug}/submit-ebook-lead`,
  verifyPhone: () => `${leads.slug}/verify-phone`
}

const events = {
  slug: 'events',
  getConfiguration: () => `${events.slug}/get-events-page`,
  getEventsSocialPosts: () =>
    `${events.slug}/get-events-page-social-media-posts`,
  submitMailingListLead: () => `${events.slug}/submit-mailing-list-lead`,
  submitStickyButtonLead: () => `${events.slug}/submit-sticky-button-lead`
}

const terms = {
  slug: 'terms',
  createUserSuggestion: () => `${terms.slug}/create-user-suggestion`
}

const intelligence = {
  slug: 'intelligence',
  getQir: () => `${intelligence.slug}/get-quarterly-report`
}

const directory = {
  slug: 'directory',
  categoriesQuicksearch: () => `${directory.slug}/categories-quicksearch`,
  getFiltersByCategoryId: () => `${directory.slug}/get-filters-by-category-id`,
  createCompany: () => `${directory.slug}/create-company`,
  updateCompany: () => `${directory.slug}/update-company`,
  saveCompanyPreview: () => `${directory.slug}/save-company-preview`,
  getDirectoryCategoryRotated: () =>
    `${directory.slug}/get-directory-categories-rotated`,
  createCompanyVideo: () => `${directory.slug}/create-company-video`,
  updateCompanyVideo: () => `${directory.slug}/update-company-video`,
  createArticle: () => `${directory.slug}/create-article`,
  updateArticle: () => `${directory.slug}/update-article`,
  quickSearch: () =>
    `${directory.slug}/globalsearch/directory-component-search`,
  uploadFmDirFile: () => `${directory.slug}/media/upload-file`,
  uploadFlDirFile: () => `${directory.slug}/media/upload-file`,
  uploadVideo: () => `${directory.slug}/videos`,
  getVideo: () => `${directory.slug}/videos`,
  pinCompanyVideo: () => `${directory.slug}/pin-company-video`
}

const notifications = {
  slug: 'notifications',
  getBellIconItems: () => `${notifications.slug}/get-bell-icon-items`,
  markReadBellIcon: () => `${notifications.slug}/mark-read-bell-icon`,
  deleteBellIconItem: () => `${notifications.slug}/delete-bell-icon-item`,
  clearAllBellIcon: () => `${notifications.slug}/clear-all-bell-icon`
}

const media = {
  slug: 'media',
  uploadFmFile: () => `${media.slug}/upload-file`,
  uploadFlFile: () => `${media.slug}/upload-file`
}

const videos = {
  slug: 'videos',
  uploadVideo: () => `${videos.slug}`,
  getVideo: () => `${videos.slug}`
}

const videoHosting = {
  slug: 'video-hosting',
  getYoutubeVideoData: externalId =>
    `${videoHosting.slug}/youtube/videos/${externalId}`
}

const homepage = {
  slug: 'homepage',
  getHomepageVideos: () => `${homepage.slug}/videos`,
  getHomepageEvents: () => `${homepage.slug}/events`,
  getFlLatestNews: () => `${homepage.slug}/market-news/all-news`,
  getFlNewsByCategory: categorySlug =>
    `${homepage.slug}/market-news/${categorySlug}`
}

const fmDirArticles = {
  slug: 'directory/articles',
  getHomepageDirectoryNews: () => `${fmDirArticles.slug}/get-company-news`
}

const flArticles = {
  slug: 'articles',
  getArticlesByCategory: () => `${flArticles.slug}/get-articles-by-category`
}

export default {
  users,
  articles,
  categories,
  globalSearch,
  leads,
  layout,
  terms,
  events,
  intelligence,
  directory,
  notifications,
  media,
  videos,
  videoHosting,
  homepage,
  fmDirArticles,
  flArticles
}
