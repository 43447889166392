export const REPORT_TYPE = {
  CUSTOM: 'Custom',
  QUARTERLY: 'Quarterly',
  QUARTERLY_OUTDATED: 'QuarterlyOutdated',
  QUARTERLY_BUNDLE: 'QuarterlyBundle'
}

export const REPORT_TYPE_TITLE_BY_REPORT_TYPE = {
  [REPORT_TYPE.CUSTOM]: 'Custom',
  [REPORT_TYPE.QUARTERLY]: 'Single',
  [REPORT_TYPE.QUARTERLY_OUTDATED]: 'Single',
  [REPORT_TYPE.QUARTERLY_BUNDLE]: 'Annual'
}

export const SECTION_TYPE = {
  MAIN: 'Main',
  TESTIMONIAL: 'Testimonial',
  QUARTERLY_REPORT: 'QuarterlyReport',
  INFOGRAPHIC: 'Infographic',
  CUSTOM_REPORT: 'CustomReport',
  ANNUAL_SUBSCRIPTION: 'AnnualSubscription',
  QUARTERLY_REPORTS_LIST: 'QuarterlyReportsList',
  INSIDER_REPORT: 'InsiderReport',
  ALL: 'All'
}
