import { prop } from 'ramda'
import { removeStartingSlash } from '@fmpedia/helpers'
import { checkIfLinkHasProtocol, processResponse } from '@/plugins/helper'
import { getCategories } from '@/utils/helpers/categories'
import { findArticleCategorySlugByCategorySlug } from 'enums/fl/fl-categories'
import { findCompanyCategorySlugByCategorySlug } from 'enums/fl/fl-directory-categories'
import { DEFAULT_FALLBACK_LINK } from 'enums/links'

export function generateArticlePath(article, { amp = false } = {}) {
  const processedArticle = processResponse(article)
  if (processedArticle.url || processedArticle.path) {
    return processedArticle.url || processedArticle.path
  }

  let categorySlug, subcategorySlug

  /* eslint-disable-next-line no-prototype-builtins */
  if (processedArticle.hasOwnProperty('parentCategory')) {
    const categoryData = getCategories(processedArticle)
    categorySlug = categoryData.categorySlug || ''
    subcategorySlug = categoryData.subCategorySlug || ''
  } else {
    categorySlug = processedArticle.categorySlug || ''
    subcategorySlug =
      processedArticle.subcategorySlug || processedArticle.subCategorySlug || ''
  }

  if (!categorySlug) {
    console.log(
      '%cCategorySlug not found from backend!',
      'color: black ; background-color: cyan'
    )
    return null
  }

  const articleSlug =
    prop('slug', processedArticle) || prop('articleSlug', processedArticle)

  if (!articleSlug) {
    console.log(
      '%carticleSlug not found from backend!',
      'color: black ; background-color: cyan'
    )
    return null
  }

  return `/${categorySlug}/${
    subcategorySlug ? subcategorySlug + '/' : ''
  }${articleSlug}/${amp ? 'amp/' : ''}`
}

export function generateCategoryPath(category) {
  // ToDo Implement more flexible options (using store actions)
  const processedCategory = processResponse(category)
  let categorySlug, subcategorySlug

  /* eslint-disable-next-line no-prototype-builtins */
  if (processedCategory.hasOwnProperty('parentCategory')) {
    const categoryData = getCategories(category)
    categorySlug = categoryData.categorySlug || ''
    subcategorySlug = categoryData.subCategorySlug || ''
  } else {
    categorySlug = processedCategory.categorySlug || ''
    subcategorySlug =
      processedCategory.subcategorySlug ||
      processedCategory.subCategorySlug ||
      ''
  }

  return `/${categorySlug}${subcategorySlug ? '/' + subcategorySlug : ''}/`
}

export function generateCategoryUrl(category, ctx) {
  return generateFmUrl(generateCategoryPath(category), ctx)
}

export function generateFmDirArticlePath(article) {
  const processedArticle = processResponse(article)

  if (processedArticle.path || processedArticle.url) {
    return processedArticle.path || processedArticle.url
  }

  if (!article.categorySlug) {
    console.log(
      '%cCategorySlug not found from backend!',
      'color: black ; background-color: cyan'
    )
  }

  const { categorySlug, companySlug } = processedArticle
  const articleSlug = processedArticle.slug

  return `/${categorySlug}/${companySlug}/${articleSlug}`
}

export function generateFlArticlePath(article, { amp = false } = {}) {
  const processedArticle = processResponse(article)

  if (processedArticle.path || processedArticle.url) {
    return processedArticle.path || processedArticle.url
  }

  const { slug, isMigrated, categorySlug } = processedArticle

  if (!categorySlug || !slug) return DEFAULT_FALLBACK_LINK

  const handledCategorySlug =
    findArticleCategorySlugByCategorySlug(categorySlug) || categorySlug

  return isMigrated
    ? `/${handledCategorySlug}/!/${slug}${amp ? '/amp/' : ''}`
    : `/${handledCategorySlug}/${slug}/${amp ? 'amp/' : ''}`
}

export function generateAuthorPath(author) {
  if (!author) return DEFAULT_FALLBACK_LINK

  const { slug } = processResponse(author)

  return `/author/${slug}/`
}

export function generateAuthorUrl(author, ctx) {
  return generateFmUrl(generateAuthorPath(author), ctx)
}

export function generateFlAuthorPath(authorSlug) {
  if (!authorSlug) return DEFAULT_FALLBACK_LINK

  return `/author/${authorSlug}`
}

export function generateFmDirCompanyUrl(company, ctx) {
  const { slug, categorySlug } = processResponse(company)

  if (!slug || !categorySlug) return null

  const link = `/${categorySlug}/${slug}/`
  return generateFmDirUrl(link, ctx)
}

export function generateFlCompanyPath(company) {
  const { slug, categorySlug } = processResponse(company)
  if (!slug || !categorySlug) return DEFAULT_FALLBACK_LINK

  const processedCategorySlug =
    findCompanyCategorySlugByCategorySlug(categorySlug) || categorySlug

  return `/${processedCategorySlug}/${slug}/`
}

export function generateTagPath(tag) {
  const { slug } = processResponse(tag) || {}

  return `/tag/${slug}/`
}

export function generateTagUrl(tag, ctx) {
  return generateFmUrl(generateTagPath(tag), ctx)
}

export function generateQirPath(qirSlug) {
  if (!qirSlug) return DEFAULT_FALLBACK_LINK

  return `/intelligence/products/qir/${qirSlug}/`
}

export function generateQirUrl(qirSlug, ctx) {
  return generateFmUrl(generateQirPath(qirSlug), ctx)
}

export function generateFullUrl(link, defaultDomain) {
  if (!defaultDomain) {
    throw new Error('generateFullUrl: defaultDomain is a required argument')
  }

  if (!link) return defaultDomain

  const linkWithoutStartSlash = removeStartingSlash(link)
  return checkIfLinkHasProtocol(linkWithoutStartSlash)
    ? link
    : `${defaultDomain}/${linkWithoutStartSlash}`
}

export function generateFmUrl(link, ctx) {
  if (!ctx) {
    throw new Error('generateFmUrl: ctx is a required argument!')
  }

  return generateFullUrl(link, ctx.$env.DOMAIN_URL)
}

export function generateFmDirUrl(link, ctx) {
  if (!ctx) {
    throw new Error('generateFmUrl: ctx is a required argument!')
  }

  return generateFullUrl(link, ctx.$env.FM_DIR_DOMAIN_URL)
}

export function generateFlUrl(link, ctx) {
  if (!ctx) {
    throw new Error('generateFmUrl: ctx is a required argument!')
  }

  return generateFullUrl(link, ctx.$env.FL_DOMAIN_URL)
}
